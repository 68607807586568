/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

const $ = require('jquery');
window.$ = $;
jQuery = $;
window.jQuery = $;
// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

var showElementInAccordion = function (element) {
    $element = $(element);
    $element.parents('.collapse').collapse('show');
}
window.showElementInAccordion = showElementInAccordion;

function showLoader(text) {
    loader = $('#ajaxLoader');
    textField = loader.children('span.text');
    
    loader.removeClass('d-none');
    textField.text(text);
}

function hideLoader() {
    loader = $('#ajaxLoader');
    textField = loader.children('span.text');
    
    loader.addClass('d-none');
    textField.text('');
}

$(document).ready(function () {
    $('[data-toggle="popover"]').popover();

    $('#nav-toggler')
        .bind('click', function (e) {
            $(this).toggleClass('d-block d-md-none');
            $('#navigation').toggleClass('d-none d-md-block');
        });

    $('form.saveOnCollapse div.collapse')
        .bind('hide.bs.collapse', function (e) {
            var form = $(this).closest('form');
            var url = form.attr('action');
            
            showLoader('Zapisywanie');

            $.ajax({
                type: "POST",
                url: url,
                data: form.serialize(), // serializes the form's elements.
                success: function (data)
                {
                    form.siblings('div.validation-container').html(data);
                    hideLoader();
                }
            });

        });

    $('div.accordion .form-error-icon').each(function (index, element) {
        showElementInAccordion(element);
    });
});


